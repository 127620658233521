import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Layout from '../components/Layout/Layout';
import {userLogout} from '../state/actions/userManagement';
import Section from '../components/Layout/Section/Section';
import Meta, {appendSeoTitleSuffix} from '../components/Layout/Meta';
import {PageTemplateStyled} from '../templates/styles';

const LogoutPage = (props) => {
	const {location, handleLogout, user} = props;

	const logout = () => handleLogout();

	logout();

	return (
		<>
			<Meta title={appendSeoTitleSuffix('Melde dich ab')} />
			<Layout location={location}>
				<PageTemplateStyled>
					<Section showPadding>
						<h1>Du hast dich erfolgreich von ms-Life abgemeldet</h1>
					</Section>
				</PageTemplateStyled>
			</Layout>
		</>
	);
};

LogoutPage.propTypes = {
	location: PropTypes.shape({pathname: PropTypes.string}).isRequired,
};

const mapStateToProps = (state) => ({
	user: state.userManagement.user,
});

const mapDispatchToProps = (dispatch) => ({
	handleLogout: () => dispatch(userLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);
