import styled from 'styled-components';
import {sizes} from '../styles/variables';
import {withUnit, media} from '../styles/utils';
import Section from '../components/Layout/Section/Section';

export const ButtonStyled = styled.div`
	margin: 0 auto ${withUnit(sizes.distance.base)};
	display: flex;
	justify-content: center;

	${media.lg`
		position: absolute;
		top: 0;
		right: 0;
	`}
`;

export const PageSectionStyled = styled(Section)`
	padding-bottom: ${withUnit(sizes.distance.base)};
`;

export const PageTemplateStyled = styled.div`
	min-height: 100vh;
	margin-top: ${withUnit(sizes.navbar.sm.default)};

	${media.lg`
		margin-top: ${withUnit(sizes.navbar.default)};
	`};
`;

export const BlogTemplateStyled = styled.div`
	margin-top: ${withUnit(sizes.navbar.sm.default)};

	${media.lg`
		margin-top: ${withUnit(sizes.navbar.default)};
	`};
`;

export const PageContentStyled = styled.div`
	.ap-image {
		margin-bottom: ${withUnit(sizes.distance.base)};

		${media.md`
			margin-bottom: ${withUnit(sizes.distance.base * 1.5)};
		`};
	}
`;

export const SectionFooterStyled = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	flex-direction: column;
	${media.md`
		flex-direction: row;
	`};
`;

export const Flexdiv = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
export const AppContainer = styled.div`
	max-width: 1600px;
	width: 100%;
	margin: 0 auto;

	${media.md`
		padding: 64px 0;
	`};
`;
